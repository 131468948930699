body {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/*
Print stylesheet for VW Tracking System
Inspired by https://www.noupe.com/design/css-perfect-print-stylesheet-98272.html
lastmodified 10.18.2019
*/
.PulseAlert {
  background-color: rgba(0, 0, 0, 0);
  animation-name: color;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
@keyframes color {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  50% {
    background-color: rgba(75, 99, 178, .7);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media print {
  /************ Setting content width, unsetting floats and margins ***********/
  #MainBody {
    width: 100%;
    margin: 0;
    float: none;
    font: 13pt 'Roboto', sans-serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
    -webkit-print-color-adjust: exact;
  }
  .HideOnPrint {
    display: none !important;
  }
  .RemoveMinHeight {
    min-height: 0 !important;
  }
  #RemoveElevation {
    box-shadow: none !important;
  }
  #InvoicePrint {
    box-shadow: none !important;
    height: 100%;
    max-width: 100%;
  }
  /****************************** Setting margins *****************************/
  @page {
    margin: 0cm;
  }
  @page :left {
    margin: 0cm;
  }
  @page :right {
    margin: 0cm;
  }
  @page :first {
    margin: 0cm 0cm;
  }

  /***** Set font to 16px/13pt, set background to white and font to black. ****/
  h1 {
    font-size: 24pt;
  }
  h2,
  h3,
  h4 {
    font-size: 14pt;
    margin-top: 25px;
  }

  /************************* Defining all page breaks *************************/
  a {
    page-break-inside: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  tr,
  pre {
    page-break-inside: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
  .pageBreakAfter {
    page-break-after: always;
  }

  /******************************* Handle Links *******************************/
  a:link,
  a:visited,
  a {
    background: transparent;
    color: #520;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
  }
  a {
    page-break-inside: avoid;
  }
  a[href^='http']:after {
    content: ' < ' attr(href) '> ';
  }
  $a:after > img {
    content: '';
  }
  article a[href^='#']:after {
    content: '';
  }
  a:not(:local-link):after {
    content: ' < ' attr(href) '> ';
  }

  /****************** Hiding Videos, iframes & Other Elements *****************/
  .entry iframe,
  ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }
  .embed-youtube,
  .embed-responsive {
    position: absolute;
    height: 0;
    overflow: hidden;
  }
  #MainMenu,
  #Toolbar,
  #MainBody .HidePrint {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
  }

  /************** Adding Messages Before and After Printing **************/
  /* Adding custom messages before and after the content */
  .entry:after {
    content: '\ All Rights Reserved. (c) 2014 - 2016 TechBrain - techbrain.de';
    color: #999 !important;
    font-size: 1em;
    padding-top: 30px;
  }

  #header:before {
    content: '\ Thank you for printing our article. We hope that some of our other articles can catch your eye as well.';
    color: #777 !important;
    font-size: 1em;
    padding-top: 30px;
    text-align: center !important;
  }
}
 /* CSS for SqPaymentForm */
 .third {
  float: left;
  width: calc((100% - 32px) / 3);
  padding: 0;
  margin: 0 16px 16px 0;
}

.third:last-of-type {
  margin-right: 0;
}

/* Define how SqPaymentForm iframes should look */
.sq-input {
  height: 56px;
  box-sizing: border-box;
  border: 1px solid #E0E2E3;
  background-color: white;
  border-radius: 6px;
  display: inline-block;
  -webkit-transition: border-color .2s ease-in-out;
     -moz-transition: border-color .2s ease-in-out;
      -ms-transition: border-color .2s ease-in-out;
          transition: border-color .2s ease-in-out;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 1px solid #4A90E2;
}

/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 1px solid #E02F2F;
}

#sq-card-number {
  margin-bottom: 16px;
}
